<template>
  <div
    class="product-intro__head j-expose__product-intro__head"
  >
    <h1
      class="product-intro__head-name fsp-element"
      :class="{
        'product-intro__head-name_bold': pageComponents.headNameConf.isBold,
        'title-line-camp': isShowMoreIcon && isTitleEllipsis
      }"
      tabindex="0"
    >
      {{ goodsName }}
      <Icon
        v-if="isShowMoreIcon && isTitleEllipsis"
        class="not-fsp-element title-expand-icon"
        name="sui_icon_more_down_14px_1"
        size="14px"
        @click="isTitleEllipsis = !isTitleEllipsis"
      />
      <Icon
        v-if="isShowMoreIcon && !isTitleEllipsis"
        class="not-fsp-element title-expand-icon"
        name="sui_icon_more_up_14px_1"
        size="14px"
        @click="isTitleEllipsis = !isTitleEllipsis"
      />
    </h1>
    <ProductSheinXEnter 
      v-if="isShowSheinX"
      :designer-info="productIntroData.designerInfo"
      :position-style="`underName`"
    />
    <div class="product-intro__head-sku-ctn">
      <div class="product-intro__head-sku">
        <span class="product-intro__head-sku-text">SKU: {{ productInfo.goods_sn }}</span>
        <sui_icon_copy_12px_1 
          v-tap="{id: '1-6-1-172'}"
          class="product-intro__head-sku-copy copy-code-btn"
          :data-clipboard-text="productInfo.goods_sn"
          size="12px"
          color="#2D68A8"
          @click="CopySKU"
        />
      </div>
      <ClientOnly>
        <ProductIntroCommentRate v-if="DETAIL_STREAM_PART.THIRD" />
      </ClientOnly>
    </div>
    
    <div
      v-if="badgeInfo || hasSellingPoints || isPreferredSeller || isInFashionStore || isQualityStore || trendsInfo"
      v-expose="getExposeLabelData()"
      class="product-intro__head-label"
    >
      <span
        v-if="isPreferredSeller"
        v-expose="{ id: '1-8-6-117' }"
        class="new-label preferred-seller"
      >
        <img
          class="preferred-icon"
          :src="`${PUBLIC_CDN}/she_dist/images/store/store-preferred-seller-bb758e2772.png`"
        />  
        {{ language.SHEIN_KEY_PC_21837 }}
      </span>
      <span
        v-if="badgeInfo"
        class="new-label"
        da-event-click="1-8-6-12"
        :style="{
          color: badgeInfo.fontColor,
          background: badgeInfo.backgroundColor,
        }"
      >
        <em>{{ badgeInfo.name }}</em>
      </span>
      <!-- 时尚店铺 || 趋势商品 -->
      <span
        v-if="isInFashionStore || trendsInfo"
        class="product-intro__fashionIcon"
      >
        <TrendsLabel only-label-style />
      </span>
      <Icon
        v-if="isQualityStore"
        name="sui_icon_choices_20px_color"
        class="product-intro__choicesIcon"
        size="67px"
        height="20px"
      />
      <!-- 卖点 -->
      <template v-if="hasSellingPoints">
        <SellingPoint
          :tag="sellingPointTag"
          :show-evolu-shein="showEvolushein"
          :show-num="2"
          :show-desc="true"
          :language="language"
          :ready="DETAIL_STREAM_PART.THIRD"
          @detail-show-selling-point="showSellingPoint"
        />
      </template>
    </div>

    <ClientOnly>
      <PricePromotionEntry v-if="DETAIL_STREAM_PART.FIRST">
        <ProductIntroHeadPrice />
        <!-- 闪购标签 （abt分支） -->
        <div
          v-if="showFlashSaleDiffData.isShow && !priceBannerTypeData.isPriceBannerTypeShow"
          class="product-intro__flashsale-diff"
        >
          {{ showFlashSaleDiffData.text }}
          <span class="flashsale-label">{{ showFlashSaleDiffData.discount }}</span>
        </div>
        <!-- 价格下方的统一提示 -->
        <ProductIntroPriceTips
          v-if="!priceBannerTypeData.isPriceBannerTypeShow && (showPriceTips || deDiscountInfo.suggestedSalePrice.show)"
          :price-tips-config="priceTipsConfig"
          :de-discount-info="deDiscountInfo"
          :compliance-mode-de="complianceModeDe"
          :language="language"
        />
        <template v-if="isShowRankInfo">
          <ProductItemRankingList
            :ranking-list="rankingListInfo"
            :language="language"
          />
        </template>
        <!-- 会员专属价格 -->
        <div
          v-if="exclusivePromotionPrice.amountWithSymbol && !s3ClubPriceInfo"
          class="vip-exclusive__detail vip-exclusive__detail-comp-page"
        >
          {{ exclusivePromotionPrice.amountWithSymbol }}
          <div
            class="vip-exclusive-txt__detail"
          >
            <span>{{ language.SHEIN_KEY_PC_17426 }}</span>
          </div>
          <i
            class="svgicon svgicon-head-help"
            da-event-click="1-8-6-43"
            @click="isShowExplainationModal(true)"
          ></i>
        </div>
        <!-- 分期提示 -->
        <div
          v-if="afterPayInfoList.length"
          class="product-intro__head-instalment-tips"
          :da-event-click="afterPayInfoList.length > 1 ? '1-8-6-50' : ''"
          @click="showMoreTip = !showMoreTip"
        >
          <div
            v-for="(item, index) in afterPayInfoList"
            v-show="showMoreTip || index == 0"
            :key="index"
            class="product-intro__head-instalment-tip"
          >
            {{ getShowDesc(item) }}
            <img
              v-if="item.logo_url"
              :src="transformImg({ img: item.logo_url })"
            />
            <a
              v-if="item.jump_url"
              :href="item.jump_url"
              target="_blank"
              da-event-click="1-8-6-11"
            >
              <i class="svgicon svgicon-gd-more"></i>
            </a>
          </div>
          <i
            v-show="afterPayInfoList.length > 1"
            class="svgicon svgicon-arrow-down product-intro__head-instalment-arrow"
            :class="{ 'product-intro__head-instalment-arrow_up': showMoreTip }"
          ></i>
        </div>

        <!-- 一口价 -->
        <UnderPriceEntry
          v-if="showUnderPrice"
          :language="language"
          :get-price="productIntroData.getPrice"
          :is-rw="IS_RW"
          :lang-path="langPath"
        />

        <AppExclusiveDiscount
          v-if="appExclusiveData"
          :data="appExclusiveData"
          :thumb-img="productInfo.goods_thumb"
          :goods-name="productInfo.goods_url_name"
          :goods-id="productInfo.goods_id"
          :sale-price="salePriceText.price"
          :retail-price="retailPriceText"
          :web-client="WEB_CLIENT"
          :promotion-label="promotionLabelText"
        />

        <!-- Normal Flash -->
        <FlashBelt
          v-if="showFlashCountBelt"
          :flash-goods="flashGoods"
          :language="language"
          :lang-path="langPath"
          :ccc-flash-zone-data="cccFlashZoneData"
          :promotion-info="promotionInfo"
          :css-right="GB_cssRight"
          :is-flash-sale-count-down-abt-hide="isFlashSaleCountDownAbtHide"
          :is-rw="IS_RW"
        />

        <!-- 未满足门槛新样式 -->
        <EstimatedTag
          v-if="showEstimatedNoThresholdNew && hasEstimated"
          class="product-intro__new-no-threshold"
          :analysis-data="{ location: 'page' }"
          :language="language"
          :estimated-info="getEstimatedInfo"
          :promotion-info="promotionInfo"
          :is-estimated-no-threshold-new-type="true"
          :is-show-add="showEstimatedNoThresholdNewAdd"
          :has-link="true"
          @click-link="clickEstimatedNoThresholdNewForAddItem"
        />
    
        <!-- 命中到手价实验时，换用新组件 -->
        <ProductIntroHeadPromotionNew
          ref="ProductIntroHeadPromotionNewRef"
          @switch-color="switchColor"
        />
        <!-- 新人券强化区 -->
        <NewUserCoupon
          v-if="showNewCoupon"
          :new-user-coupon="newUserCouponInfo.newUserCoupon"
          :coupon-language="couponLanguage"
          :new-user-discount="newUserCouponInfo.newUserDiscount"
          :new-user-coupon-rule="newUserCouponInfo.newUserCouponRule"
          @get-coupon="getNewUserCoupon"
        />
        <ExtraVoucherFloor
          v-if="extraVoucherPromotionInfo && extraVoucherPromotionInfo.isExtraVoucherFloorType"
          :extra-voucher-promotion-info="extraVoucherPromotionInfo"
          @click="openExtraVoucherFloorPromotion"
        />

        <PaidVipEstimated
          v-if="showVipEstimatedTag"
          class="product-intro__head-paidvip-estimated"
          :is-needs-to-renew="isNeedsToRenew"
          :text="vipEstimatedText"
          :data="sheinClubPromotionInfo"
          :paid-vip-data="paidVipData"
          :price-detect="priceDetect"
          :show-shein-club="showSheinClub"
          :is-paid-user="isPaidUser"
          :is-free-receive-user="isFreeReceiveUser"
          :show-club-type="showClubType"
          :language="language"
          :cdn-url="PUBLIC_CDN"
          :css-right="GB_cssRight"
          :goods-id="productInfo.goods_id"
          source="goodsDetail"
          :vip-estimated-class="vipEstimatedClass"
          :is-estimated-anti-content="isEstimatedAntiContent"
          :detail-shein-club-content="detailSheinClubContent"
          @get-shein-club-user-info="getSheinClubUserInfo"
        />
        <FreeShippingBelt
          v-if="showFreeShippingBelt"
          :belt-quickship-strong-config="beltQuickshipStrongConfig"
          :language="language"
        />
      </PricePromotionEntry>
    </ClientOnly>

    <LazyHydrationWrapper :when-triggered="DETAIL_STREAM_PART.THIRD">
      <ProductIntroHeadSharePopup />
    </LazyHydrationWrapper>

    <ClientOnly v-if="isShowExplaination">
      <modal-vue
        :show="isShowExplaination"
        :twobtn="false"
        @cancel="isShowExplainationModal(false)"
        @ok="isShowExplainationModal(false)"
      >
        <template #title>
          <span>
            {{ language.SHEIN_KEY_PC_17426 }}
          </span>
        </template>
        <div v-html="language.SHEIN_KEY_PC_17472"></div>
      </modal-vue>
    </ClientOnly>
  </div>
</template>

<script>
import 'public/src/icon/head-help.svg'
import 'public/src/icon/gd-more.svg'
import 'public/src/icon/arrow-down.svg'
import { Icon, sui_icon_copy_12px_1 } from '@shein-aidc/icon-vue3'
import { defineAsyncComponent } from 'vue'
import ProductIntroHeadPromotionNew from '../components/ProductIntroHeadPromotionNew'
import FreeShippingBelt from 'public/src/pages/goods_detail_v2/components/FreeShipping/FreeShippingBelt.vue'
import FlashBelt from 'public/src/pages/goods_detail_v2/components/Belt/FlashBelt.vue'
import PaidVipEstimated from '../../components/PaidVipEstimated'
import NewUserCoupon from '../../components/NewUserCoupon'
import UnderPriceEntry from './UnderPriceEntry'
import SellingPoint from '../../components/SellingPoint'
import ProductSheinXEnter from './ProductSheinXEnter'
import ProductIntroPriceTips from './ProductIntroPriceTips'
import ProductIntroHeadPrice from './ProductIntroHeadPrice'
import ExtraVoucherFloor from 'public/src/pages/goods_detail_v2/components/ExtraVoucher/ExtraVoucherFloor.vue'
import ProductIntroHeadSharePopup from './ProductIntroHeadSharePopup'
import ProductItemRankingList from 'public/src/pages/goods_detail_v2/components/RecommendRelatives/RankInfo/ProductItemRankingList.vue'

import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import { template, transformImg } from '@shein/common-function'
import { expose } from 'public/src/pages/common/analysis/directive.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { ClientOnly } from '@sheinfe/vue-client-only'
import schttp from 'public/src/services/schttp'
import EstimatedTag from 'public/src/pages/goods_detail_v2/components/EstimatedTag/index.vue'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import Clipboard from 'clipboard'
import { LazyHydrationWrapper } from '@shein-aidc/shein-lazy-hydration'

import PricePromotionEntry from 'public/src/pages/goods_detail_v2/components/PricePromotionEntry/container.vue'
import TrendsLabel from './TrendsLabel.vue'

daEventCenter.addSubscriber({ modulecode: '1-8-6' })
daEventCenter.addSubscriber({ modulecode: '2-22' })

export default {
  name: 'ProductIntroHead',
  
  directives: { expose },
  components: {
    LazyHydrationWrapper,
    Icon,
    PricePromotionEntry,
    ProductIntroHeadPromotionNew,
    AppExclusiveDiscount: defineAsyncComponent(() => import(/* webpackChunkName: "product-intro-appexclusive-dis" */'../../components/AppExclusiveDiscount')),
    FreeShippingBelt,
    FlashBelt,
    ClientOnly,
    PaidVipEstimated,
    SellingPoint,
    ProductSheinXEnter,
    UnderPriceEntry,
    EstimatedTag,
    ProductIntroCommentRate: defineAsyncComponent(() => import(/* webpackChunkName: "product-intro-comment-rate" */'../components/ProductIntroCommentRate')),
    ProductIntroHeadSharePopup,
    NewUserCoupon,
    ProductIntroPriceTips,
    ProductIntroHeadPrice,
    ExtraVoucherFloor,
    sui_icon_copy_12px_1,
    TrendsLabel,
    ProductItemRankingList
  },
  data() {
    return {
      isShowExplaination: false, // s3专属折扣价解释弹窗
      hasExposedExclusivePrice: false,
      showMoreTip: false,
      initListener: false,
      isTitleEllipsis: true,
      isShowMoreIcon: false,

      isGetAgain: false, // 是否再次领取
      loginStatus: false,
      realTimeLoaded: false
    }
  },
  computed: {
    ...mapState([
      'productIntroData',
      'skuInfo',
      'language',
      'badgeInfo',
      'promotionData',
      'langPath',
      'lang',
      'IS_RW',
      'GB_cssRight',
      'PUBLIC_CDN',
      'WEB_CLIENT',
      'isLoginChange',
      'quickShip',
      // 'primeShippingReady',
      'DETAIL_STREAM_PART',
      // 'realtimePriceStatus'
    ]),
    ...mapGetters([
      'isMatchCateId',
      'priceDetect',
      'pageComponents',
      'promotionInfo',
      'mallCode',
      'sheinClubPromotionInfo',
      'detailSignBoardData',
      'screenAbtConfig',
      'freeShippingInfo',
      'getEstimatedInfo',
      'newUserCouponInfo',
      'bestDealConfig',
      'lowestPriceTipsConfig',
      'estimatedAbtest',
      'taxIncludedText',
      'isShowAddMore',
      'sheinClubShippingSiteConfig',
      'showSheinClub',
      'flashGoods',
      'flashType',
      'beltQuickshipStrongConfig',
      'isSpecialFlash',
      'inFashionStoreImg',
      'isInversion',
      'detailSheinClubContent',
      'isEstimatedAntiContent',
      'complianceMode',
      'complianceModeDe',
      'exclusivePromotionPrice',
      'newFlashPromotion',
      'extraVoucherPromotionInfo',
      'isInversionDe',
      'deDiscountInfo',
      'isPaidUser',
      'isQualityStore',
      'couponLanguage',
      'afterPayInfo',
      'isInFashionStore',
      's3ClubPriceInfo',
      'isNeedsToRenew', // 付费会员需要续费
      'trendsInfo',
      'rankingListInfo'
    ]),
    showNewCoupon(){
      const NewCoupon = this.screenAbtConfig?.NewCoupon?.p?.NewCoupon
      return NewCoupon === 'New1' &&  this.isEstimatedMatch && this.newUserCouponInfo?.newUserCoupon
    },
    isShowRankInfo(){
      return this.screenAbtConfig?.Rankingoptiz?.p?.Rankingoptiz === 'new' && this.rankingListInfo?.score
    },
    showClubType(){
      // 推全poskey：clubtype=new状态
      // return this.screenAbtConfig?.clubtype?.p?.clubtype === 'new' || false
      return true
    },
    // 付费会员入口样式
    vipEstimatedClass() {
      return this.screenAbtConfig?.SHEINclubshowtype?.p?.SHEINclubshowtype
    },
    // 付费会员入口样式
    vipEstimatedClassNew() {
      return ['New1', 'New2'].includes(this.vipEstimatedClass)
    },
    showFreeShippingBelt () {
      // 包邮 && abt 腰带样式
      return this.freeShippingInfo?.freeShippingStyle?.isStrip || false
    },
    cccFlashZoneData () {
      return this.productIntroData?.cccFlashZoneData || {}
    },
    goodsName() {
      return this.productIntroData?.detail?.goods_name || ''
    },
    flashSaleDiff() {
      return !this.IS_RW
    },
    productInfo() {
      return this.productIntroData.detail
    },
    paidVipData() {
      return this.promotionData.find(_ => _.typeId == 29)
    },
    isFreeReceiveUser() {
      return this.productIntroData.isFreeReceiveUser
    },
    appExclusiveData() {
      const appPromotion = this.productInfoPrice?.appPromotion || []
      return appPromotion.find(item => item.typeId == 8)
    },
    productInfoPrice() {
      const { flashType, newFlashPromotion } = this
      let mallPrice = this.productInfo.mall[this.mallCode] || {}
      if (flashType == 4 && newFlashPromotion?.price?.amountWithSymbol) {
        mallPrice = {
          ...mallPrice,
          salePrice: newFlashPromotion?.price || {},
          discountPrice: newFlashPromotion?.discount_value || {},
          unit_discount: newFlashPromotion?.discount_percent || '',
        }
      }
      return mallPrice
    },
    price() {
      let result
      if (this.skuInfo && this.skuInfo.mall) {
        result = this.skuInfo.mall[this.mallCode]
      } else {
        result = this.productInfoPrice
      }
      let iscloseEstimatedAndAbPriceWithPromo32 = this.productIntroData.closeEstimatedAndAbPrice && this.promotionInfo?.[0]?.typeId == 32
      return {
        ...result,
        ...( iscloseEstimatedAndAbPriceWithPromo32 ? { salePrice: result.retailPrice, unit_discount: 0 } : {})
      }
    },
    flashGoods() {
      return this.promotionInfo.find(item => item.typeId == 10)
    },
    flashType() {
      return this.flashGoods?.flashType
    },
    normalFlashStyle() {
      const { flashType, newFlashPromotion } = this
      return [1, 5, 6].includes(flashType) || (flashType == 4 && newFlashPromotion)
    },
    showFlashCountBelt () {
      return this.normalFlashStyle && this.IS_RW
    },
    // 是否为普通闪购商品
    isFlashGoods() {
      return !!this.flashGoods && this.normalFlashStyle
    },
    isSpecialFlash() {
      return [2, 3].includes(this.flashType) && this.newFlashPromotion?.price?.amountWithSymbol
    },
    isBrandFlash() {
      return this.flashType == 6
    },
    // 是否有零售价
    hasRetailPrice() {
      const { flashType, newFlashPromotion } = this
      return (
        this.price?.salePrice?.amountWithSymbol !== this.price?.retailPrice?.amountWithSymbol
        || (flashType == 4 && newFlashPromotion?.price?.amountWithSymbol)
      )
    },
    // 分期付款
    // afterPayInfo() {
    //   if (
    //     this.productIntroData.afterPayInfo &&
    //     Object.keys(this.productIntroData.afterPayInfo).length
    //   ) {
    //     return this.productIntroData.afterPayInfo
    //   } else {
    //     return false
    //   }
    // },
    afterPayInfoList() {
      const fontShowList =
        (this.afterPayInfo && this.afterPayInfo.front_show_list) || []
      let afterPayInfoList = []
      fontShowList.forEach(item => {
        let showDescBatch = item.show_desc_batch || []
        if (
          (showDescBatch.length &&
            showDescBatch.every(i => i.desc || item.show_desc)) ||
          item.show_desc
        )
          afterPayInfoList.push(item)
      })
      return (
        (this.afterPayInfo &&
          this.afterPayInfo.is_display &&
          afterPayInfoList) ||
        []
      )
    },
    showFrom() {
      // 同一个skc下有多个sku价格并且没有选中尺码时展示from
      return this.priceDetect && !this.skuInfo.sku_code
    },
    salePriceText() {
      const price = (this.isPaidUser && this.sheinClubPromotionInfo) ?
        this.sheinClubPromotionInfo.price.amountWithSymbol : 
        this.salePrice
      if (this.showFrom) {
        return {
          price: this.globalTempalte(
            `<span class="j-expose__product-intro__head-from ltr">${price}</span>`,
            this.language.SHEIN_KEY_PC_17875
          ),
          adaPrice: this.language.SHEIN_KEY_PC_17875 + ' ' + price
        }
      }
      return {
        price: `<span>${price}</span>`,
        adaPrice: price
      }
    },
    retailPriceText() {
      return this.hasRetailPrice
        ? this.price?.retailPrice.amountWithSymbol
        : ''
    },
    salePrice() {
      return this.price?.salePrice?.amountWithSymbol
    },
    promotionLabelText() {
      const { GB_cssRight } = this
      const showLabel =
        (this.hasRetailPrice && !this.isFlashGoods) ||
        (!this.flashSaleDiff && this.isFlashGoods && !this.showFrom)
      const symbol = this.lang != 'li' ? '-' : ''
      const labelOne = this.price
        ? GB_cssRight
          ? `${this.price.unit_discount}%${symbol}`
          : `${symbol}${this.price.unit_discount}%`
        : ''
      const labelTwo = GB_cssRight
        ? `${this.price.unit_discount}%${symbol}`
        : `${symbol}${this.price.unit_discount}%`

      return showLabel ? labelOne || labelTwo : ''
    },
    showEvolushein() {
      return this.screenAbtConfig?.evolushein?.p?.evolushein === 'show'
    },
    hasSellingPoints() {
      return this.sellingPointTag.length
    },
    sellingPointTag() {
      let getSellingPoints = this.productIntroData.getSellingPoints || []
      let sellingPointSort = []
      for (let tag of getSellingPoints) {
        for (let tagValue of tag.tags) {
          tagValue.tag_id = tag.tag_id
          // showEvolushein 为false，只push非60008057 、60004616的卖点信息（abt：evolushein 命中show，0008057 、60004616两个卖点才会显示）
          if(this.showEvolushein || (!this.showEvolushein && !['60004616', '60008057'].includes(tagValue.tag_val_id))){
            sellingPointSort.push(tagValue)
          }
        }
      }

      sellingPointSort = sellingPointSort.sort(
        (a, b) => a.tag_sort - b.tag_sort
      )
      return sellingPointSort
    },
    isPreferredSeller() {
      const { storeData = {}, showStore = false } = this.detailSignBoardData || {}
      const { labelList = [] } = storeData
      const hasPreferredSeller = labelList.find(label => label.labelCode === 'preferredSeller')
      return showStore && hasPreferredSeller
    },
    showUnderPrice() {
      if (this.isInversion || this.isInversionDe) return false
      const { screenAbtConfig, productIntroData } = this
      const { getPrice = {} } = productIntroData
      const showFromAbt = screenAbtConfig?.UnderPrice?.param?.UnderPriceShow == 'A'
      if (!showFromAbt || !getPrice?.suitRulePrice) {
        return false
      }
      return true
    },
    isShowSheinX() {
      return !!this.productIntroData?.designerInfo?.designer_name
    }, 
    isEstimatedMatch () {
      let { EstimatedPrice } = this.screenAbtConfig || {}
      let E_Price_Cal = EstimatedPrice?.p?.E_Price_Cal
      return ['A', 'B', 'C', 'D', 'Non_Threshold', 'Have_Threshold'].includes(E_Price_Cal)
    },
    estimatedCompProps () {
      return this.getEstimatedInfo?.estimatedCompProps || null
    },
    hasEstimated () {
      return !!this.estimatedCompProps && !!this.estimatedCompProps?.config
    },
    showVipEstimatedTag () {
      return (this.isPaidUser || (!this.isPaidUser && this.showSheinClub)) &&
        !!this.sheinClubPromotionInfo
    },
    vipEstimatedText () {
      if(this.detailSheinClubContent){
        return this.detailSheinClubContent
      }
      const { sheinClubShippingSiteConfig, vipEstimatedClass } = this
      let value = this.price?.prev_discount_value?.amountWithSymbol
      if (!value) return ''

      let vipEstimatedText = value
      let vipEstimatedNew = ['New1', 'New2'].includes(vipEstimatedClass)
      if(vipEstimatedNew){
        vipEstimatedText = `<span style="color: #C44A01; font-weight: 700;">${value}</span>`
      }
      
      if(this.isPaidUser){
        return template(vipEstimatedText, this.language.SHEIN_KEY_PC_26352) 
      } else {
        // if (!this.primeShippingReady) return ''
        if (!sheinClubShippingSiteConfig.IS_NEW_SHEIN_CLUB_SHIPPING) {
          if(this.isFreeReceiveUser && this.showClubType){
            return template(vipEstimatedText, this.language.SHEIN_KEY_PC_28207)
          } else {
            return template(vipEstimatedText, this.language.SHEIN_KEY_PC_26351)
          }
        } else {
          const { VALUE_DETAIL = {} } = sheinClubShippingSiteConfig
          if (VALUE_DETAIL.single_coupon_value) { // 当有付费会员运费券时
            let text = vipEstimatedNew ? `<span style="color: #C44A01; font-weight: 700;">${VALUE_DETAIL.single_coupon_value_with_symbol}</span>` : VALUE_DETAIL.single_coupon_value_with_symbol
            return template(text, this.language.SHEIN_KEY_PC_29254)
          } else if (VALUE_DETAIL.multiple_points) { // 当没有会员运费券 但有奖励积分权益时
            let text = vipEstimatedNew ? `<span style="color: #C44A01; font-weight: 700;">${VALUE_DETAIL.multiple_points}</span>` : VALUE_DETAIL.multiple_points
            return template(text, this.language.SHEIN_KEY_PC_29255)
          } else { // 当既没有运费券又没有积分权益时
            return this.language.SHEIN_KEY_PC_29256
          }
        }
      }
    },
    priceBannerTypeData() {
      let isPriceBannerTypeAbtNew = !this.IS_RW
      let isPriceBannerTypeShow = false
      isPriceBannerTypeShow = isPriceBannerTypeAbtNew && !!(this.normalFlashStyle || this.isSpecialFlash)
      const { language = {}, flashType = {}, isSpecialFlash, normalFlashStyle } = this
      let title = ''
      if (isSpecialFlash) {
        let specialFlashTitleMap = {
          '2': language.SHEIN_KEY_PC_23050,
          '3': language.SHEIN_KEY_PC_23051
        }
        title = specialFlashTitleMap[flashType]
      }
      if (!isSpecialFlash && normalFlashStyle) {
        title = language.SHEIN_KEY_PC_15950
        if (flashType == 1) {
          title = language.SHEIN_KEY_PC_15950 // 常规闪购
        } else if ([4, 5].includes(Number(flashType))) {
          const { titleKey } = this.cccFlashZoneData || {} 
          title = language[titleKey] || language?.SHEIN_KEY_PC_27483 || language?.SHEIN_KEY_PC_15950 // 专区兜底文案
        } else if (this.isBrandFlash) {
          title = language.SHEIN_KEY_PC_29165
        }
      }

      let showRightIcon = false
      if (this.normalFlashStyle && !this.isBrandFlash) showRightIcon = true
      // if (this.isBrandFlash && this.screenAbtConfig.brandskip?.p == 'Jumpable') showRightIcon = true

      return {
        isPriceBannerTypeAbtNew,
        isPriceBannerTypeShow,
        title,
        showRightIcon
      }
    },
    showNewCouponList () {
      let { EstimatedPrice } = this.getEstimatedInfo || {}
      let E_Price_Cal = EstimatedPrice?.p?.E_Price_Cal
      // 命中促销楼层新样式
      return ['Non_Threshold', 'Have_Threshold'].includes(E_Price_Cal) || this.getEstimatedInfo?.isAb
    },
    showFlashSaleDiffData() {
      let isShow = false
      let {
        flashSaleDiff,
        isFlashGoods,
        isSpecialFlash,
        hasEstimated,
        showEstimatedNoThresholdNew
      } = this
      isShow = flashSaleDiff && isFlashGoods && !isSpecialFlash
      if (hasEstimated) {
        isShow = showEstimatedNoThresholdNew ? isShow : false
      }
      return {
        isShow,
        text: this.globalTempalte(
          this.productInfoPrice?.discountPrice?.amountWithSymbol,
          this.language.SHEIN_KEY_PC_17461
        ),
        discount: `${this.lang != 'il' ? '-' : ''}${this.price.unit_discount}%`
      }
    },
    bestDealTipsAnalysis() {
      const { mallCode, bestDealConfig, price, productIntroData, quickShip } = this
      const { retailPrice, salePrice } = price || {}
      const { tipsType } = bestDealConfig || {}
      const { goods_id, goods_sn, productRelationID } = productIntroData.detail || {}
      const goods_list = `${goods_id}\`${goods_sn}\`${productRelationID}\`1\`1\`1\`\`\`pri_${salePrice?.usdAmount}|pri_${retailPrice?.usdAmount}\`${quickShip == 1 ? 'quickship_1' : ''}\`\`mall_${mallCode}\``
      return {
        pricestatus: tipsType,
        location: 'detail',
        goods_list
      }
    },
    priceTipsConfig() {
      const { taxIncludedText } = this
      const { text, type } = this.bestDealConfig
      const showBestDeal = text && !!~type.indexOf('Text')
      return {
        best_deal: {
          show: showBestDeal,
          content: text,
          analysis: this.bestDealTipsAnalysis
        },
        tax_included: {
          show: !!taxIncludedText,
          content: taxIncludedText,
        }
      }
    },
    showPriceTips() {
      const { best_deal, tax_included } = this.priceTipsConfig
      return best_deal.show || tax_included.show
    },
    showEstimatedNoThresholdNew() {
      let { EstimatedNothreShowType, isSatisfied } = this.getEstimatedInfo || {}
      return ['New1', 'New2'].includes(EstimatedNothreShowType) && !isSatisfied
    },
    showEstimatedNoThresholdNewAdd() {
      return this.isShowAddMore
    },
    isFlashSaleCountDownAbtHide() {
      return this.screenAbtConfig?.FlashSaleCountDown?.p?.FlashSaleCountDown === 'Hide'
    },
  },
  watch: {
    exclusivePromotionPrice: {
      handler(newVal) {
        // 专属价格曝光
        if (newVal.exclusivePromotionPrice)
          // eslint-disable-next-line no-undef
          this.handleExclusivePriceExpose(data) // TODO
      },
      immediate: true
    },
    badgeInfo() {
      if (this.badgeInfo) {
        // 角标曝光
        this.handleBadgeInfoExpose()
      }
    },
    afterPayInfoList: {
      immediate: true,

      handler(list) {
        if (!!list.length) {
          // 支付信息前置曝光
          daEventCenter.triggerNotice({
            daId: '1-8-6-100',
            extraData: {
              payment_code: this.afterPayInfo?.payment_code
            }
          })
        }
      }
    },
    isLoginChange() {
      this.loginStatus = true
      if(this.isEstimatedMatch){
        this.updateEstimatedCouponList()
      }else{
        this.fetchCouponList()
      }
    },
    loginStatus (val) {
      if (val) {
        if (this.isGetAgain) {
          this.getNewUserCoupon()
          this.isGetAgain = false
        }
      }
    },
    goodsName: {
      handler() {
        if (typeof window !== 'undefined') {
          // 加载计算goodsName高度
          this.calGoodsNameHeight()
        }
      },
      immediate: true,
    },
    lowestPriceTipsConfig: {
      handler(val) {
        if (typeof window !== 'undefined' && val.hitLowestPrice) {
          daEventCenter.triggerNotice({
            daId: '1-8-6-193',
            extraData: {
              priceday: val.hitLowestPrice
            }
          })
        }
      },
      immediate: true,
    },
    mallCode() {
      this.fetchCouponList()
    },
    'DETAIL_STREAM_PART.THIRD': {
      handler(val) {
        val && this.thirdPartHandle()
      },
      // immediate: true
    }
  },
  methods: {
    ...mapMutations(['assignState']),
    ...mapActions(['updateRealTimePrice', 'asyncHotModles', 'getRealtimePrice', 'getProductBadges', 'getCCCDescConfig', 'fetchCouponList', 'updateEstimatedCouponList', 'showLoginModal']),
    globalTempalte: template,
    thirdPartHandle()  {

      this.loginStatus = isLogin()
      this.fetchCouponList()
      // afterPayInfo
      if (this.afterPayInfo && this.afterPayInfo.is_display) {
        daEventCenter.triggerNotice({
          daId: '1-8-6-28'
        })
        if (
          this.afterPayInfo.front_show_list &&
        this.afterPayInfo.front_show_list.length > 1
        ) {
          daEventCenter.triggerNotice({
            daId: '1-8-6-49'
          })
        }
      }
    },
    calGoodsNameHeight(){
      this.isShowMoreIcon = false
      this.$nextTick(()=>{
        let nameDom = document.querySelector('.product-intro__head-name')
        if (nameDom) {
          let descHeight = window.getComputedStyle(nameDom).height.replace('px', '')
          this.isShowMoreIcon = descHeight > 36
        }
      })
    },
    transformImg,
    isShowExplainationModal(isShow) {
      this.isShowExplaination = isShow
    },
    handleExclusivePriceExpose() {
      if (this.exclusivePromotionPrice && !this.hasExposedExclusivePrice) {
        daEventCenter.triggerNotice({
          daId: '1-8-6-44'
        })
        this.hasExposedExclusivePrice = true
      }
    },
    handleBadgeInfoExpose() {
      daEventCenter.triggerNotice({
        daId: '1-8-6-30',
        extraData: {
          product_category_id: this.productInfo.cat_id,
          productsku: this.productInfo.goods_sn,
          goods_label: this.badgeInfo['name-en']
        }
      })
    },
    getShowDesc(item) {
      let currentPrice = this.price?.salePrice?.amount
      return (
        (item.show_desc_batch &&
          item.show_desc_batch.find(i => i.amount == currentPrice) &&
          item.show_desc_batch.find(i => i.amount == currentPrice)['desc']) ||
        item.show_desc
      )
    },
    showSellingPoint(sellingPoints) {
      daEventCenter.triggerNotice({
        daId: '1-8-6-70',
        extraData: {
          sellingPoints
        }
      })
    },
    getSheinClubUserInfo(params) {
      this.assignState({ sheinClubUserInfo: params })
    },
    clickEstimatedNoThresholdNewForAddItem () {
      this.$refs.ProductIntroHeadPromotionNewRef?.addMoreHandle?.()
    },
    switchColor (color) {
      this.$emit('switch-color', color)
    },
    loginHandle() {
      this.showLoginModal({
        type: 'detail_coupon',
        originRef: this,
      })
    },
    CopySKU(){
      const clipboard = new Clipboard(`.copy-code-btn`)
      clipboard.on('success', () => {
        this.$message({
          message: this.language.SHEIN_KEY_PC_30336 || 'Copied Successfully!',
          type: 'success',
        })
        clipboard.destroy()
      })
    },
    getNewUserCoupon(){
      // 点击领取新人券
      daEventCenter.triggerNotice({
        daId: '1-8-6-172',
        extraData: {
          operation_results: 1
        },
      })
      const { newUserCoupon } = this.newUserCouponInfo
      const isReceived = newUserCoupon?.bind_status === 1
      const isBindCoupon = newUserCoupon?.is_bind === 1
      if(isBindCoupon && !isReceived){
        if (!this.loginStatus) { // 判断是否登录
          this.isGetAgain = true
          this.loginHandle()
          return
        }        
        this.assignState({ viewPromotion: true })
        schttp({
          method: 'POST',
          url: '/api/productInfo/bindCoupon/post',
          data: {
            coupon_codes: [newUserCoupon?.coupon_code]
          },
        }).then(res => {
          this.updateEstimatedCouponList() // 更新到手价相关数据
          if (!res) return
          if (res.code == '0') {
            const success = res.info?.successList?.length
            if (res.info?.failureList?.length) { // 绑定失败
              const errorCode = res.info.failureList[0].code
              this.$message({ message: this.couponLanguage[errorCode], type: 'warning' })
            }
            if (success) { // 绑定成功
              this.$message({
                message: this.couponLanguage.SHEIN_KEY_PC_22091,
                type: 'success',
              })
            }
            daEventCenter.triggerNotice({
              daId: '1-8-6-93',
              extraData: {
                coupon_id: newUserCoupon?.coupon_code,
                status: success ? 1 : 0,
                abtest: this.estimatedAbtest
              }
            })
          }
        })
      } else{
        this.assignState({ viewPromotion: true })
      }
    },
    openExtraVoucherFloorPromotion() {
      this.$refs?.ProductIntroHeadPromotionNewRef.openCouponDrawer?.()
      daEventCenter.triggerNotice({
        daId: '1-8-6-226',
        extraData: {
          location: 1
        }
      })
    },
    getExposeLabelData() {
      let exposeValue = {}

      const hasNew = this.badgeInfo
      const hasTrend = this.isInFashionStore || this.trendsInfo
      if (hasNew || hasTrend) {
        const isOnlyNew = hasNew && !hasTrend
        const isOnlyTrend = hasTrend && !hasNew
        exposeValue = {
          id: '1-8-6-237',
          once: true,
          data: {
            location: 'detail',
            label: isOnlyNew ? 'new' : isOnlyTrend ? 'trend' : 'new`trend',
          }
        }
      }

      return exposeValue
    }
  },
  emits: ['switch-color'],
}
</script>

<style lang="less">
@import "./style/vipExclusive";
div.selling-point-label__ctn {
  margin-top: 0; /*// 覆盖product-item.less中卖点的样式*/
}
.selling-point-label {
  &__viewMore {
    color: #1860a7;
    margin-top: 10px;
    cursor: pointer;
    /* rw:begin */
    color: #ff9999;
  }
  &__title {
    color: #222;
    font-weight: bold;
    line-height: 16px;
    margin-bottom: 5px;
    word-wrap: break-word;
  }
  &__desc {
    line-height: 14px;
    word-wrap: break-word;
    color: #222;
  }
}
.product-intro {
  &__head {
    position: relative;
    margin-bottom: 25px;
    .selling-point-label {
      &__item {
        cursor: pointer;
        margin-right: 8px;
        > span {
          max-width: 100%;
          display: inline-block;
          color: #767676;
          background-color: #fff1d5;
          line-height: 20px;
          padding: 0 6px;
          font-size: 12px;
          .text-overflow();
          /* rw:begin */
          background-color: #fff7f0;
        }
      }
    }
  }
  &__head-paidvip.paidvip {
    margin-top: 20px;
  }
  &__head-paidvip-estimated {
    margin-top: 10px;
  }
  &__new-no-threshold {
    margin-top: 15px;
  }
  &__head-name {
    font-size: 14px;
    margin-bottom: 5px;
    padding-right: 48px;
    text-transform: none;
    font-weight: normal;
    word-break: break-word;
    position: relative;
    &.title-line-camp{
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .title-expand-icon {
      position: absolute;
      bottom: -3px;
      right: 48px;
      background: #fff;
      box-shadow: -10px 0px 10px 3px #fff;
      cursor: pointer;
    }
    &_bold {
      font-weight: bold;
    }
  }
  &__head-sku-ctn {
    height: 20px;
    .flexbox();
    align-items: center;
    margin-bottom: 10px;
    font-size: 12px;
  }
  &__head-sku {
    color: #767676 /*rw: #959595*/;
    .flexbox();
    align-items: center;
    overflow: hidden;
  }
  &__head-sku-text{
    .text-overflow();
    flex: 1;
  }
  &__head-sku-copy{
    cursor: pointer;
    margin: 0 12px 0 2px;
  }
  &__head-reviews {
    .common-rate {
      margin-right: 5px;
      display: inline-block;
    }
  }
  &__head-reviews-text {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  &__head-label {
    .flexbox();
    margin-bottom: 15px;
    .new-label {
      margin-right: 8px;
      padding: 0 10px;
      height: 20px;
      line-height: 20px;
      color: #fff;
      display: inline-block;
      font-size: 12px;
      /*rw:begin*/
      font-family: "Adieu";
      font-weight: 700;
      > em {
        font-style: normal;
        margin-left: 1px;
      }
      &.preferred-seller {
        display: flex;
        align-items: center;
        padding: 0 4px;
        border-radius: 2px;
        border: .5px solid rgba(168, 97, 4, 0.15);
        background: #FFF8EB;
        color: #A86104;
        font-family: "SF UI Text";
        .preferred-icon {
          margin-right: 4px;
          width: 14px;
          height: 14px;
        }
      }
    }
  }

  &__fashionIcon {
    margin-right: 8px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &__choicesIcon{
    margin-right: 8px;
  }
  &__head-price {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    // * {
    //   direction: ltr /*rtl:ignore*/;
    // }
    i.flasesale-icon {
      align-self: flex-end;
      font-size: 24px;
      color: @sui_color_flash;
    }
    i.limitedoff-icon {
      margin-right: 5px;
      width: 18px;
      height: 18px;
    }
    .sui_icon_time_16px_1 {
      font-size: 18px;
      color: @sui_color_promo;
    }
    .discount {
      color: @sui_color_discount;
      font-size: 24px;
      font-weight: 700;
      font-family: "Arial Black";
      margin-right: 4px;
    }
    .original {
      color: #222;
      font-size: 24px;
      font-weight: 700;
      font-family: "Arial Black";
    }
    
  }
  &__head-instalment-tips {
    margin: 10px 0 14px;
    color: #999;
    padding: 12px 16px;
    padding-top: 2px;
    background: #f6f6f6;
    font-size: 12px;
    position: relative;
    a {
      display: inline-block;
      text-decoration: none;
      margin-left: 4px;
    }
    img {
      height: 16px;
    }
  }
  &__head-instalment-tip {
    margin-top: 10px;
  }
  &__head-instalment-tip {
    max-width: 95%;
  }
  &__head-instalment-arrow {
    position: absolute;
    right: 16px;
    top: 13px;
    transition: transform 0.25s;
    &_up {
      transform: rotate(180deg);
    }
  }

  &__flashsale-diff {
    color: @sui_color_discount;
    padding-top: 8px;
    line-height: 18px;
    /* sh:begin */
    /* rw:begin */
    color: #ff9999;
    .flashsale-label,
    .discount-label {
      //min-width: 47px;
      padding: 0 8px;
      height: 18px;
      line-height: 18px;
      text-align: center;
      display: inline-block;
      font-size: 12px;
      direction: ltr /*rtl:ignore*/;
      background-color: @sui_color_flash;
      color: #222;
      vertical-align: bottom;
      margin-left: 5px;
      /* rw:begin */
      color: #fff;
    }
  }
}



.price-sket__container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: gray;
}

</style>
